<template>
  <b-modal
    :id="`modal-detail-fares-train-${itineraryIndex}-${trainId}`"
    title="Bảng giá vé tàu"
    title-class="font-medium-4 fw-600 text-primary"
    header-bg-variant="light-primary"
    centered
    size="lg"
    body-class="px-25 px-lg-1"
    @hide="hideModal"
    @show="showModal"
  >
    <IAmOverlay
      :loading="loadingGetFares"
      :spinner-variant="'info'"
    >
      <!--
      <div class="fw-800 mb-1">
        {{ $t('train.search.result.trainPromotions') }}
        Bảng giá vé tàu {{ trainCode }}
      </div>
      -->

      <!-- <template v-if="!isEmpty(trainPromotion) && !isEmpty(trainPromotion.listPromotionDisplay)"> -->

      <b-card
        class="overflow-hidden"
        body-class="p-75"
      >
        <div class="fw-700 font-medium-3 text-center mb-50 text-uppercase">
          BẢNG GIÁ VÉ -
          <span
            v-if="trainName"
            class="text-danger"
          >
            Tàu {{ trainName }}
          </span>
        </div>

        <b-table
          class="mb-50 border rounded-8px"
          bordered
          responsive
          small
          hover
          :items="dataFare"
          :busy="isBusy"
          :show-empty="!isBusy && !loadingGetFares && isEmpty(dataFare)"
          :empty-text="$t('noMatchingResult')"
          :fields="['STT', 'seatName', 'seatCode', 'ticketPrice']"
          thead-class="text-dark font-weight-bolder"
        >
          <template
            v-for="column in ['STT', 'seatName', 'seatCode', 'ticketPrice']"
            #[`head(${column})`]="data"
          >
            <span :key="column.key">
              {{ $t(`train.search.result.columns.${data.column}`) }}
            </span>
          </template>

          <template #table-busy>
            <div class="text-center text-warning my-2">
              <b-spinner class="align-middle" />
              <strong class="ml-1">{{ $t('train.search.result.searching') }}</strong>
            </div>
          </template>

          <template #cell(STT)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(seatName)="{item}">
            {{ item.seatTypeName }}
          </template>
          <template #cell(seatCode)="{item}">
            <span class="fw-700">{{ item.seatTypeCode }}</span>
          </template>
          <template #cell(ticketPrice)="{item}">
            <span
              v-if="item.totalPrice"
              :class="`fw-700 text-info
                    ${isMobileView ? 'font-medium-1' : 'font-medium-2'}
                  `"
            >
              {{ formatCurrency(item.totalPrice) }}
            </span>
            <span
              v-else
              class="fw-700 font-medium-1 text-danger"
            >
              {{ $t('train.search.result.notHavePrice') }}
            </span>
          </template>
        </b-table>
      </b-card>
    </IAmOverlay>

    <template #modal-footer="{ cancel }">
      <div class="d-flex-center w-100">
        <b-button
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('reservation.back') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BModal, BCard, BSpinner, BTable,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'

import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'

import useTrainHandle from '@train/useTrainHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BSpinner,
    BTable,

    IAmOverlay,
  },
  props: {
    trainId: {
      type: Number,
      required: true,
    },
    trainCode: {
      type: String,
      required: true,
    },
    itineraryIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      loadingGetFares,
      getBookingCode,
    } = useTrainHandle()
    const { toastError } = useToast()

    const dataFare = ref([])
    const trainName = ref('')
    const isBusy = ref(false)

    async function getFaresByTrain() {
      const trainId = props.trainId
      const payload = {
        bookingCode: getBookingCode.value,
        trainId,
      }

      isBusy.value = true

      try {
        const data = await store.dispatch('app-train/searchFaresByTrain', payload)
        dataFare.value = !isEmpty(data?.fareList) && data.fareList.every(it => isNumber(it.totalPrice)) ? data.fareList.sort((a, b) => b.totalPrice - a.totalPrice) : []
      } catch (error) {
        toastError({ title: 'train.search.result.searchError' })
        console.error('error getTrainFare', error)
      } finally {
        isBusy.value = false
      }
    }

    async function showModal() {
      // trainName.value = ''
      // isBusy.value = false
      // dataFare.value = []
      trainName.value = props.trainCode

      getFaresByTrain()
    }

    function hideModal() {
      trainName.value = ''
      isBusy.value = false
      dataFare.value = []
    }

    return {
      isEmpty,
      showModal,
      hideModal,
      isBusy,
      dataFare,
      trainName,
      formatCurrency,
      convertISODateTime,
      loadingGetFares,
    }
  },
}
</script>
