var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-detail-fares-train-".concat(_vm.itineraryIndex, "-").concat(_vm.trainId),
      "title": "Bảng giá vé tàu",
      "title-class": "font-medium-4 fw-600 text-primary",
      "header-bg-variant": "light-primary",
      "centered": "",
      "size": "lg",
      "body-class": "px-25 px-lg-1"
    },
    on: {
      "hide": _vm.hideModal,
      "show": _vm.showModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('div', {
          staticClass: "d-flex-center w-100"
        }, [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")])], 1)];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingGetFares,
      "spinner-variant": 'info'
    }
  }, [_c('b-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "body-class": "p-75"
    }
  }, [_c('div', {
    staticClass: "fw-700 font-medium-3 text-center mb-50 text-uppercase"
  }, [_vm._v(" BẢNG GIÁ VÉ - "), _vm.trainName ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" Tàu " + _vm._s(_vm.trainName) + " ")]) : _vm._e()]), _c('b-table', {
    staticClass: "mb-50 border rounded-8px",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "hover": "",
      "items": _vm.dataFare,
      "busy": _vm.isBusy,
      "show-empty": !_vm.isBusy && !_vm.loadingGetFares && _vm.isEmpty(_vm.dataFare),
      "empty-text": _vm.$t('noMatchingResult'),
      "fields": ['STT', 'seatName', 'seatCode', 'ticketPrice'],
      "thead-class": "text-dark font-weight-bolder"
    },
    scopedSlots: _vm._u([_vm._l(['STT', 'seatName', 'seatCode', 'ticketPrice'], function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key
          }, [_vm._v(" " + _vm._s(_vm.$t("train.search.result.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-warning my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.$t('train.search.result.searching')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(STT)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(seatName)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.seatTypeName) + " ")];
      }
    }, {
      key: "cell(seatCode)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(item.seatTypeCode))])];
      }
    }, {
      key: "cell(ticketPrice)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.totalPrice ? _c('span', {
          class: "fw-700 text-info\n                  ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-2', "\n                ")
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice)) + " ")]) : _c('span', {
          staticClass: "fw-700 font-medium-1 text-danger"
        }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.notHavePrice')) + " ")])];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }